<template>
  <v-row class="rowDialog">
    <v-dialog
      persistent
      v-model="dialog"
      :fullscreen="$vuetify.breakpoint.smAndDown"
      max-width="430"
      class="dialog"
    >
      <v-card class="contentCard">
        <v-card-title class="headline pb-0">
          <v-col cols="10" class="modal-title">Nova Transferência</v-col>
          <v-spacer></v-spacer>
          <v-col class="icon" cols="1">
            <v-icon
              v-if="transfer && successTransfer"
              @click="closeModal"
              class="close-icon"
            >mdi-window-close</v-icon>
            <v-icon
              v-if="!successTransfer"
              @click="closeOnlyModal"
              class="close-icon"
            >mdi-window-close</v-icon>
          </v-col>
        </v-card-title>
        <v-divider class="mx-6 ml-6"></v-divider>
        <v-col v-if="getLoading" cols="12" align="center">
          <v-progress-circular
          :size="100"
          color="#D70472"
          indeterminate
          ></v-progress-circular>
          <h3 v-if="!transfer" class="textInfoDisable mt-6">Validando os dados...</h3>
          <h3 v-else class="textInfoDisable mt-6">Confirmando a transferência...</h3>
        </v-col>
        <v-card-text v-show="!getLoading">
          <v-row
            justify="center"
            v-if="!transfer && !successTransfer && !errorTransfer && contactState"
          >
            <v-col md="8" cols="12" class="pb-0">
              <p class="title-modal mb-0">Revisão</p>
              <p class="text-modal pb-0 pt-2 mb-0">Valor</p>
              <p class="title-modal value mb-0">{{contactState.value}}</p>
              <p class="text-modal pb-0 mb-0 pt-4">{{contactState.name}}</p>
              <p class="text-modal data-text mb-0">
                {{contactState.document.length === 14 ? service.maskToDocument(contactState.document) : contactState.document }}
              </p>
            </v-col>
            <v-col md="8" class="pt-md-2 pt-0">
              <v-row justify="center">
                <v-col cols="6" class="pt-md-0">
                  <p class="column-title mb-md-1 mb-0">Agência</p>
                </v-col>
                <v-col cols="6" class="pt-md-0">
                  <p class="column-text mb-md-1 mb-0">{{contactState.branch}}</p>
                </v-col>
                <v-col cols="6" class="pt-md-0">
                  <p class="column-title mb-md-1 mb-0">Conta</p>
                </v-col>
                <v-col cols="6" class="pt-md-0">
                  <p class="column-text mb-md-1 mb-0">{{`${contactState.account}-${contactState.digit}`}}</p>
                </v-col>
                <v-col cols="4" class="pt-md-0" v-if="getBankName !== ''">
                  <p class="column-title mb-md-1 mb-0">Banco</p>
                </v-col>
                <v-col cols="8" class="pt-md-0" v-if="getBankName !== ''">
                  <p class="column-text mb-md-1 mb-0">{{getBankName}}</p>
                </v-col>
                <v-col cols="6" class="pt-md-0">
                  <p class="column-title mb-md-1 mb-0">Tipo de conta</p>
                </v-col>
                <v-col cols="6" class="pt-md-0">
                  <p class="column-text mb-md-1 mb-0">{{getAccountTypeName()}}</p>
                </v-col>
                <v-col cols="6" class="pt-md-0">
                  <p class="column-title mb-md-1 mb-0">Data</p>
                </v-col>
                <v-col cols="6" class="pt-md-0">
                  <p class="column-text mb-md-1 mb-0">{{$moment().format('DD/MM/YYYY')}}</p>
                </v-col>
              </v-row>
            </v-col>
            <v-col md="8">
              <v-btn
                @click="validateTransfer"
                rounded
                dark
                color="#00C1C1"
                class="button-modal pa-6 pa-md-1"
              >Confirmar Transferência</v-btn>
            </v-col>
            <v-col md="8">
              <v-btn
                @click="closeOnlyModal"
                rounded
                color="#8C8C8C"
                outlined
                class="button-modal pa-6 pa-md-1"
              >Cancelar</v-btn>
            </v-col>
          </v-row>
          <v-row justify="center" v-if="transfer && !successTransfer && !emailSended">
            <v-row justify="center" v-if="error">
              <v-col cols="12" md="10" align="center">
                <v-alert class="alert" dense outlined v-if="error">{{error}}</v-alert>
              </v-col>
            </v-row>
            <v-col md="9" cols="12" class="pt-5" align="center">
              <p
                class="titlePassword"
              >Para completar a transferência, insira a senha da sua conta digital:</p>
            </v-col>
            <v-col md="7" cols="10" class="mt-0 pb-0 password">
              <v-text-field
                class="password-field"
                maxlength="6"
                placeholder="------"
                rounded
                type="password"
                v-mask="'######'"
                single-line
                dense
                outlined
                v-model="password"
              />
            </v-col>
            <v-col cols="12" align="center">
              <h5 class="forgetPassword" @click="forgetMyPassword">Esqueci minha senha.</h5>
            </v-col>
            <v-col cols="12" md="9" class="pb-5 pt-md-5 pt-12" align="center">
              <v-btn
                @click="validatePassword"
                rounded
                color="#00C1C1"
                :disabled="!hasPassword"
                :dark="hasPassword"
                :ligth="!hasPassword"
                class="button-modal pa-6 pa-md-1"
              >Confirmar transferência</v-btn>
              <v-btn
                @click="closeModal"
                rounded
                color="#8C8C8C"
                outlined
                class="button-modal pa-6 pa-md-1 mt-5 mt-md-3"
              >Cancelar</v-btn>
            </v-col>
            <v-col cols="12"></v-col>
          </v-row>
          <v-row justify="center" v-if="errorTransfer">
            <v-col cols="12">
              <v-row justify="center">
                <img class="imgSuccess mt-md-5 mt-12" src="@/assets/erro.png" v-if="errorTransfer" />
              </v-row>
            </v-col>
            <v-col cols="9" md="8" class="pb-10">
              <v-row justify="center" v-if="error">
                <span class="text-erro">Erro na transferência</span>
                <span class="text-confirm">{{error}}</span>
                <v-btn
                  @click="closeOnlyModal"
                  rounded
                  color="#8C8C8C"
                  outlined
                  class="button-modal pa-6 pa-md-1 mt-5"
                >Fechar</v-btn>
              </v-row>
            </v-col>
          </v-row>
          <v-row justify="center" v-if="transfer && successTransfer">
            <v-col cols="12" class="mt-md-5 mt-12" align="center">
              <img src="@/assets/success.svg" />
            </v-col>
            <v-col cols="12" align="center">
              <h2 class="titlePassword" v-if="externalAccount && isInternal">Transferência solicitada</h2>
              <h2 class="titlePassword" v-else>Transferência realizada</h2>
            </v-col>
            <v-col cols="12" md="8" align="center">
              <h2 class="titlePassword">Parabéns</h2>
                <h5 class="infoSuccess" v-if="externalAccount && isInternal">
                Sua transferência no valor de
                <b>{{contactState.value}}</b>
                para
                <b>{{contactState.name}}</b>
                foi solicitada
              </h5>
              <h5 class="infoSuccess" v-else>
                Sua transferência no valor de
                <b>{{contactState.value}}</b>
                para
                <b>{{contactState.name}}</b>
                foi efetuada com sucesso
              </h5>
            </v-col>
            <v-col cols="9" class="pb-5" align="center">
              <v-btn
                @click="$emit('receipt', transactionId)"
                rounded
                v-if="!isInternal && externalAccount || isInternal && !externalAccount || !isInternal && !externalAccount"
                color="#00C1C1"
                dark
                class="button-modal pa-6 pa-md-1"
              >Ver Comprovante</v-btn>
              <v-btn
                @click="closeModal"
                rounded
                color="#8C8C8C"
                outlined
                class="button-modal pa-6 pa-md-1 mt-5"
              >Fechar</v-btn>
            </v-col>
          </v-row>
          <v-row justify="center" v-if="emailSended">
            <v-col cols="12" class="mt-md-5 mt-12" align="center">
              <img src="@/assets/email.png" />
            </v-col>
            <v-col cols="12" align="center">
              <h2 class="textPassword">Enviamos as insruções para o seu e-mail cadastrado!</h2>
            </v-col>
            <v-col cols="9" class="pb-5 pt-3" align="center">
              <v-btn
                @click="closeModal"
                rounded
                color="#8C8C8C"
                outlined
                class="button-modal pa-6 pa-md-1 mt-5"
              >Fechar</v-btn>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import { mask } from "vue-the-mask";
import service from "../../../service";

export default {
  directives: {
    mask,
  },
  props: ["dialog", "favorited", "externalAccount"],
  data() {
    return {
      emailSended: false,
      errorTransfer: false,
      emailFail: false,
      service,
      password: "",
      sucess: false,
    };
  },
  methods: {
    ...mapActions({
      validate: "DigitalAccount/validateTransfer",
      approveTransfer: "DigitalAccount/approveTransfer",
      generateTokenTransfer: "DigitalAccount/generateTokenTransfer",
      forgetPassword: "DigitalAccount/forgetPassword",
    }),
    ...mapMutations({
      clearError: "DigitalAccount/CLEAR_ERROR",
      clearTransfer: "DigitalAccount/SET_TRANSFER",
      clearSuccessTransfer: "DigitalAccount/CLEAR_SUCCESS_TRANSFER",
    }),
    closeModal() {
      this.clearError();
      this.clearTransfer(null);
      this.clearSuccessTransfer();
      this.$router.push({ path: `/customer/digitalAccount/transfers` });
      this.emailSended = false;
      this.password = "";
      this.$emit("closeModal");
    },
    showReceipt() {
      this.$emit("seeReceiptTransfer", this.transactionId);
    },
    closeOnlyModal() {
      this.$emit("closeModal");
      this.emailSended = false;
      this.errorTransfer = false;
      this.clearSuccessTransfer();
      this.clearError();
      this.clearTransfer(null);
    },
    async validateTransfer() {
      const payload = {
        branchDestination: parseInt(this.contactState.branch),
        accountDestination: parseInt(this.contactState.account),
        accountDigitDestination: parseInt(this.contactState.digit),
        totalAmount: parseFloat(
          this.service.cleanCurrency(this.contactState.value),
          10
        ),
        currency: "BRL",
        nickName: this.contactState.nickName,
        transactionDate: this.$moment().format("YYYY/MM/DD"),
        bankDestination: parseInt(this.contactState.bank, 10),
        accountTypeDestination: this.contactState.accountType === "c" ? 1 : 2,
        documentDestination: this.service.cleanDocument(
          this.contactState.document
        ),
        name: this.contactState.name,
      };
      await this.validate(payload);
      if (this.error) {
        this.errorTransfer = true;
      }
    },
    async validatePassword() {
      await this.generateTokenTransfer(this.password);
      this.password = "";
      if (!this.error && this.transfer) {
        this.transfer.favorited = this.favorited;
        await this.approveTransfer(this.transfer);
        if (this.error) {
          this.errorTransfer = true;
          this.password = "";
          this.clearTransfer(null);
        }
      }
    },
    getAccountTypeName() {
      const accountType =
        this.contactState.accountType === "c" ? "Corrente" : "Poupança";
      return accountType;
    },
    async forgetMyPassword() {
      await this.forgetPassword();
      if (!this.error) {
        this.emailSended = true;
      } else {
        this.emailSended = false;
      }
    },
  },
  computed: {
    ...mapGetters({
      getBanks: "Registration/getBanks",
      contactState: "DigitalAccount/getContact",
      transfer: "DigitalAccount/getTransfer",
      error: "DigitalAccount/getError",
      successTransfer: "DigitalAccount/getSuccessTransfer",
      transactionId: "DigitalAccount/getIdTransfer",
      role: "Auth/getRole",
      emailSuccess: "DigitalAccount/getEmailPassword",
      isInternal: 'Auth/isInternal',
      getLoading: 'Loading/getLoading'
    }),
    bankNames() {
      return this.getBanks;
    },
    hasPassword() {
      return this.password.length === 6;
    },
    getBankName() {
      const bank = this.getBanks.find(
        (item) => item.key === this.contactState.bank
      );
      return bank ? bank.value : '';
    },
  },
  created() {
    this.errorTransfer = false;
    this.clearError();
    this.clearTransfer(null);
    this.clearSuccessTransfer();
  },
  destroyed() {
    this.password = "";
  },
};
</script>
<style lang="scss" scoped>
@import "~@/assets/styles/digitalAccount/dataTransference.scss";
</style>
