<template>
  <v-container>
    <v-row justify="center">
      <v-col md="12" class="pr-md-12 pl-md-12">
        <v-col
          md="8"
          cols="12"
          class="data-transference-title"
        >Saldo total disponível na conta digital:</v-col>
        <v-col md="3" cols="12" class="text-field pt-2 pb-0">
          <v-text-field
            class="value-field"
            color="#8C8C8C"
            :value="service.formatCurrency(getDigitalBalance)"
            single-line
            readonly
            outlined
          ></v-text-field>
        </v-col>
        <v-row>
          <v-col cols="12" md="6">
            <h5 class="title-data pl-3">Dados da transferência:</h5>
          </v-col>
          <v-spacer></v-spacer>
          <v-col md="2" cols="6" class="pr-1 mr-md-5 btn-account" v-if="hasPermissionTransferFinago">
            <v-btn class="btnTransfer" @click="selectAccount" :outlined="otherAccounts" color="#D70472" dark :disabled="!hasPermissionTransferOthers">Entre contas Finago</v-btn>
          </v-col>
          <v-col md="2" cols="6" class="btn-account" v-if="hasPermissionTransferOthers">
            <v-btn @click="selectAccount"  class="btnTransfer" color="#00C1C1" :outlined="!otherAccounts" dark :disabled="!hasPermissionTransferFinago">
              Para outros bancos
            </v-btn>
          </v-col>
        </v-row>
          <v-form
           @submit.prevent="submit"
            ref="form"
            lazy-validation
          >
        <v-col md="6" cols="12" class="pl-2 pl-md-3" v-if="!otherAccounts">
          <v-text-field
            label="CNPJ"
            placeholder="00.000.000/0000-00"
            v-mask="'##.###.###/####-##'"
            required
            v-model="documentIntern"
            validate-on-blur
            type="tel"
            :rules="documentRules"
          ></v-text-field>
        </v-col>
        <v-col md="6" cols="12" class="pl-2 pl-md-3" v-if="otherAccounts">
          <v-text-field
            label="CNPJ ou CPF"
            placeholder="00.000.000/0000-00"
            v-mask="['###.###.###-##', '##.###.###/####-##']"
            required
            validate-on-blur
            v-model="contact.document"
            type="tel"
            :rules="documentRules"
          ></v-text-field>
        </v-col>
        <v-row class="pl-md-3 pl-2 pr-2">
          <v-col md="6" cols="12" class="pt-0">
            <v-text-field
              v-model="contact.name"
              :readonly="!otherAccounts"
              label="Titular"
              placeholder="Insira o nome do titular"
              required
              validate-on-blur
             :rules="nameRules"
              maxlength="60"
            ></v-text-field>
          </v-col>
          <v-col md="6" cols="12" class="pt-0">
            <v-text-field
              v-model="contact.nickName"
              label="Apelido"
              placeholder="Insira um apelido"
              maxlength="20"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row class="pl-md-3 pl-2 pr-2">
          <v-col md="3" cols="8" class="pt-md-6 pb-0" v-if="otherAccounts">
            <v-autocomplete
              ref="bankName"
              v-model="contact.bank"
              :items="bankNames"
              label="Banco"
              item-text="value"
              item-value="key"
              placeholder="Selecione o banco"
              required
              :rules="bankRules"
            ></v-autocomplete>
          </v-col>
          <v-col md="3" cols="6" class="pb-0 pt-0 pt-md-3" v-if="!otherAccounts">
            <v-text-field
              v-model="contact.branch"
              label="Agência"
              :readonly="!otherAccounts"
              placeholder="0000"
              validate-on-blur
              required
              v-mask="'####'"
             :rules="branchRules"
            ></v-text-field>
          </v-col>
          <v-col md="3" cols="6" class="pb-0 pt-0 pt-md-3" v-if="!otherAccounts">
            <v-text-field
              v-model="contact.account"
              label="Conta"
              :readonly="!otherAccounts"
              placeholder="00000-0"
              validate-on-blur
              required
              :rules="accountRules"
              v-mask="'####################'"
            ></v-text-field>
          </v-col>

          <v-col md="3" cols="4" class="pb-0 pt-0 pt-md-3" v-if="otherAccounts">
            <v-text-field
              v-model="contact.branch"
              label="Agência"
              :readonly="!otherAccounts"
              placeholder="0000"
              validate-on-blur
              required
              v-mask="'####'"
             :rules="branchRules"
            ></v-text-field>
          </v-col>
          <v-col md="2" cols="3" class="pb-0 pt-0 pt-md-3" v-if="otherAccounts">
            <v-text-field
              v-model="contact.account"
              label="Conta"
              :readonly="!otherAccounts"
              placeholder="00000"
              validate-on-blur
              required
              :rules="accountRules"
              v-mask="'####################'"
            ></v-text-field>
          </v-col>
          <v-col md="1" cols="1" class="pb-0 pt-0 pt-md-3" v-if="otherAccounts">
            <v-text-field
              v-model="contact.digit"
              label="Dígito"
              :readonly="!otherAccounts"
              placeholder="0"
              v-mask="'#'"
            ></v-text-field>
          </v-col>
          <v-col md="3" cols="8" class="pt-md-6 pt-3 pb-0" v-if="otherAccounts">
            <v-select
              ref="account"
              v-mask="'#####-#'"
              v-model="contact.accountType"
              :items="accounts"
              label="Tipos de conta"
              placeholder="Selecione"
              required
              :rules="accountTypeRules"
            ></v-select>
          </v-col>
          <v-col md="6" cols="12" class="pt-md-3 pt-3 pb-0" v-if="!otherAccounts">
            <v-text-field
              label="Informe o valor que deseja transferir"
              v-money="money"
              required
              :rules="valueRules"
              maxlength="16"
              v-model="contact.value"
              validate-on-blur
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row class="pl-md-3 pl-2 pr-2">
          <v-col md="3" cols="12" class="pt-0" v-if="otherAccounts">
            <v-text-field
              label="Informe o valor que deseja transferir"
              v-money="money"
              required
              :rules="valueRules"
              v-model="contact.value"
              maxlength="16"
              validate-on-blur
            ></v-text-field>
          </v-col>
          <v-col md="5" cols="12" class="pt-md-8" v-if="showFavorited && hasPermissionContact">
            <v-icon v-if="favoriteIcon" @click="favorite" class="mr-2 mb-2">
              mdi-star
            </v-icon>
            <v-icon v-if="!favoriteIcon" @click="favorite" class="mr-2 mb-2">
              mdi-star-outline
            </v-icon>
            <span class="favorite-text" @click="favorite">
              Adicione esse contato aos seus favoritos
            </span>
          </v-col>
        </v-row>
        </v-form>
        <v-row class="row-buttons">
          <v-col md="3" cols="12">
            <v-btn class="button" @click="back" color="#8C8C8C" rounded outlined dark>Voltar</v-btn>
          </v-col>
          <v-col md="3" cols="12">
            <v-btn @click="setContactTransfer" class="button" color="#D70472" rounded dark>
              Confirmar
            </v-btn>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
   <transfer @closeModal="closeModal" @receipt="seeReceiptTransfer" :favorited="favoriteIcon" :dialog="dialog" :externalAccount="otherAccounts"/>
   <Receipt :dialog="dialogReceipt" @closeModalReceipt="closeModalReceipt"/>
  </v-container>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import { mask } from 'vue-the-mask';
import { VMoney } from 'v-money';
import service from '../../../service';
import Transfer from './modalTransfer.vue';
import Receipt from './modalReceipt.vue';
import roles from '../../../config/permissions';

export default {
  directives: {
    mask,
    money: VMoney,
  },
  components: {
    Transfer,
    Receipt,
  },
  data() {
    return {
      documentIntern: '',
      accounts: [
        { text: 'Corrente', value: 'c' },
        { text: 'Poupança', value: 'p' },
      ],
      money: {
        decimal: ',',
        thousands: '.',
        prefix: 'R$ ',
        suffix: '',
        precision: 2,
      },
      otherAccounts: Boolean,
      showFavorited: true,
      dialog: false,
      dialogReceipt: false,
      favoriteIcon: false,
      service,
      hasPermissionContact: false,
      hasPermissionTransferFinago: false,
      hasPermissionTransferOthers: false,
      contact: {
        document: '',
        bank: '',
        branch: '',
        accountType: '',
        name: '',
        nickName: '',
        value: '00,00',
        account: '',
        digit: '',
      },
      documentRules: [
        valueInput => (!!valueInput && valueInput.length >= 14) || 'Documento inválido',
      ],
      bankRules: [
        valueInput => !!valueInput || 'Banco inválido',
      ],
      branchRules: [
        valueInput => (!!valueInput && valueInput.length >= 1) || 'Agência inválida',
      ],
      nameRules: [
        valueInput => !!valueInput || 'Nome inválido',
      ],
      accountRules: [
        valueInput => (!!valueInput && valueInput.length >= 1) || 'Conta inválida',
      ],
      accountTypeRules: [
        valueInput => !!valueInput || 'Tipo de conta inválida',
      ],
      valueRules: [
        valueInput => (!!valueInput && this.service.cleanCurrencyAll(valueInput) >= 20.00) || 'Digite um valor entre R$20,00 e R$99.999.999,99',
        valueInput => (!!valueInput && this.service.cleanCurrencyAll(valueInput) < 100000000) || 'Digite um valor entre R$20,00 e R$99.999.999,99',
      ],
    };
  },
  watch: {
    dialog(value) {
      if (!value) {
        this.closeModal();
        this.$emit('closed');
      }
    },
    documentIntern(value) {
      if(value !== this.contact.document || value === '')
      if(!this.otherAccounts){
        this.contact.name = '';
        this.contact.account = '';
        this.contact.branch = '';
        this.contact.accountType = '';
        this.contact.bank = '';
        this.contact.nickName = '';
        this.contact.digit = '';
      }
      if (!this.contact.name) {
        this.handleChange(value);
      }
    },
  },
  methods: {
    ...mapActions({
      setBalanceDigital: 'DigitalAccount/setBalance',
      setBanks: 'Registration/setBanks',
      validate: 'DigitalAccount/validateTransfer',
      receiptTransfer: 'DigitalAccount/receiptTransfer',
      getAccountByDocument: 'DigitalAccount/getAccountByDocument',
      hasRole: 'User/hasRole',
    }),
    ...mapMutations({
      clearError: 'DigitalAccount/CLEAR_ERROR',
      clearTransfer: 'DigitalAccount/SET_TRANSFER',
      clearSuccessTransfer: 'DigitalAccount/CLEAR_SUCCESS_TRANSFER',
      setContact: 'DigitalAccount/SET_CONTACT',
    }),

    async handleChange() {
      if (this.documentIntern && this.documentIntern.length >= 14) {
        await this.getAccountByDocument(this.service.cleanDocument(this.documentIntern));
        if (!this.error) {
          this.populateFormData(this.accountTransfer);
        }
      }
    },
    populateFormData(data) {
      const {
        document,
        name,
        account,
        branch,
      } = data;

      this.contact.document = document;
      this.contact.name = name;
      this.contact.account = account.toString();
      this.contact.branch = branch.toString();
      this.contact.accountType = 'c';
      this.contact.bank = '9999';
    },
    favorite() {
      if (this.favoriteIcon === false) {
        this.favoriteIcon = !this.favoriteIcon;
      } else {
        this.favoriteIcon = false;
      }
    },
    selectAccount() {
        this.otherAccounts = !this.otherAccounts;
        this.contact.document = '';
        this.documentIntern = '';
        this.contact.name = '';
        this.contact.account = '';
        this.contact.branch = '';
        this.contact.accountType = '';
        this.contact.bank = '';
        this.contact.nickName = '';
        this.contact.value = '0,00';
        this.contact.digit = '';
        this.favoriteIcon = false;
    },
    back() {
      return this.$router.push({ path: `/customer/digitalAccount/transfers` });
    },
    async initialize() {
      if (this.contactState) {
        this.contact = this.contactState;
        this.showFavorited = false;
      }
      await this.setBalanceDigital();
    },
    setContactTransfer() {
      if (this.$refs.form.validate()) {
        this.setContact(this.contact);
        this.dialog = true;
      }
    },
    async closeModal() {
      this.dialog = false;
      this.clearError();
      this.clearTransfer(null);
      this.clearSuccessTransfer();
    },
    async closeModalReceipt() {
      this.$router.push({ path: `/customer/digitalAccount/transfers` });
      this.dialogReceipt = false;
      this.clearError();
    },
    async seeReceiptTransfer(payload) {
      await this.receiptTransfer(payload);
      this.dialogReceipt = true;
      this.closeModal();
    },
    getDocument() {
      if(this.contact.document) {
        this.contact.document = this.service.documentFormat(this.contact.document)
        this.documentIntern = this.service.documentFormat(this.contact.document)
        if(this.contact.bank === "9999") {
          this.otherAccounts = false;
        } else {
          this.otherAccounts = true
        }
      }
    },
    setPermission(){
      if(this.hasPermissionTransferFinago && !this.hasPermissionTransferOthers) {
        this.otherAccounts = false
      } else if (!this.hasPermissionTransferFinago && this.hasPermissionTransferOthers) {
        this.otherAccounts = true
      }
    }
  },
  computed: {
    ...mapGetters({
      getDigitalBalance: 'DigitalAccount/getBalance',
      getBanks: 'Registration/getBanks',
      contactState: 'DigitalAccount/getContact',
      transfer: 'DigitalAccount/getTransfer',
      error: 'DigitalAccount/getError',
      role: 'Auth/getRole',
      accountTransfer: 'DigitalAccount/getAccount',
    }),
    dataRegistration() {
      return this.$store.state.DigitalAccount.accountTransfer;
    },
    bankNames() {
      return this.getBanks;
    },
  },
  async created() {
    await this.initialize();
    await this.setBanks();
    this.getDocument();
    this.hasPermissionContact = await this.hasRole(roles.CUSTOMER_WALLET_FAVORITE_CONTACT_WRITE.role);
    this.hasPermissionTransferFinago = await this.hasRole(roles.CUSTOMER_WALLET_INTERNALTRANSFER_WRITE.role);
    this.hasPermissionTransferOthers = await this.hasRole(roles.CUSTOMER_WALLET_EXTERNALTRANSFER_WRITE.role);
    this.setPermission();
  },
  destroyed() {
    this.contact.value = '0,00';
  },
};
</script>

<style lang="scss" scoped>
@import "~@/assets/styles/digitalAccount/dataTransference.scss";
</style>
